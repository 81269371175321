import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static values = {
    id: Number
  }

  static targets = ['favoriteBtn', 'itineraryBtn', 'visitedBtn']

  loggedIn = false
  in_favorites = false
  in_itinerary = false
  in_visited = false

  apiEndpoint = '/graphql'

  favorites_copy = {
    active: 'Favorited!',
    inactive: 'Add to favorites'
  }

  itinerary_copy = {
    active: 'In itinerary!',
    inactive: 'Add to itinerary'
  }

  visited_copy = {
    active: 'In visited!',
    inactive: 'Add to visited'
  }  

  connect () {
    if (window.algolia_app_user == null || Object.keys(window.algolia_app_user).length === 0) {
      this.element.classList.add('hidden')
    } else {
      this.user = window.algolia_app_user
      this.loggedIn = true
      // Check if the URL does NOT contain the word 'find-wine'
      // Now that retailers also comes from Algolia, we don't want to run the setStatus code on the retailers algolia page, since the values in the user.visited are winery ids, not retailer ids.  
      if (!window.location.href.includes('find-wine')) {
        this.setStatus()
      }
    }
  }

  setStatus () {
    if (this.user.favorites.includes(this.idValue)) {
      this.setFavoriteToActive()
    }
    if (this.user.itinerary?.includes(this.idValue)) {
      this.setItineraryToActive()
    }
    if (this.user.visited.includes(this.idValue)) {
      this.setVisitedToActive()
    }
  }

  toggleFavorite () {
    if (this.in_favorites) {
      const mutation = this.getActionMutation('favorite', 'remove')
      this.sendRequest(mutation)
        .then(response => {
          if (this.successfulResponse(response, mutation)) {
            this.setFavoriteToInactive()
          }
        })
        .catch(this.onRequestError)
    } else {
      const mutation = this.getActionMutation('favorite', 'add')
      this.sendRequest(mutation)
        .then(response => {
          if (this.successfulResponse(response, mutation)) {
            this.setFavoriteToActive()
          }
        })
        .catch(this.onRequestError)
    }
  }

  toggleItinerary () {
    if (this.in_itinerary) {
      const mutation = this.getActionMutation('itinerary', 'remove')
      this.sendRequest(mutation)
        .then(response => {
          if (this.successfulResponse(response, mutation)) {
            this.setItineraryToInactive()
          }
        })
        .catch(this.onRequestError)
    } else {
      const mutation = this.getActionMutation('itinerary', 'add')
      this.sendRequest(mutation)
        .then(response => {
          if (this.successfulResponse(response, mutation)) {
            this.setItineraryToActive()
          }
        })
        .catch(this.onRequestError)
    }
  }

  toggleVisited () {
    if (this.in_visited) {
      const mutation = this.getActionMutation('visited', 'remove')
      this.sendRequest(mutation)
        .then(response => {
          if (this.successfulResponse(response, mutation)) {
            this.setVisitedToInactive()
          }
        })
        .catch(this.onRequestError)
    } else {
      const mutation = this.getActionMutation('visited', 'add')
      this.sendRequest(mutation)
        .then(response => {
          if (this.successfulResponse(response, mutation)) {
            this.setVisitedToActive()
          }
        })
        .catch(this.onRequestError)
    }
  }

  sendRequest (mutation) {
    return axios.post(this.apiEndpoint, this.mutationQuery(mutation))
  }

  successfulResponse (response, mutation) {
    if (response.status !== 200) {
      throw new Error(`Responded with status: ${response.status}`)
    }

    if (response.data.data[mutation].errors != null) {
      throw new Error(response.data.data[mutation].errors[0].message)
    }

    return true
  }

  onRequestError (error) {
    console.error(error)
  }

  mutationQuery (mutation) {
    return {
      query: `mutation {
        ${mutation}(input: {
          token: "${this.user.token}",
          winery_id: ${this.idValue}
        }) {
          winery {
            id
          }
          errors
        }
      }`
    }
  }

  getActionMutation (key, action) {
    return {
      favorite: { add: 'addFavorite', remove: 'removeFavorite' },
      itinerary: { add: 'addWineryToItinerary', remove: 'removeWineryFromItinerary' },
      visited: { add: 'addVisited', remove: 'removeVisited'}
    }[key][action]
  }

  setFavoriteToActive () {
    this.in_favorites = true
    this.favoriteBtnTarget.classList.add('active')
    this.favoriteBtnTarget.querySelector('.action-pill-tooltip').innerHTML = this.favorites_copy.active
  }

  setFavoriteToInactive () {
    this.in_favorites = false
    this.favoriteBtnTarget.classList.remove('active')
    this.favoriteBtnTarget.querySelector('.action-pill-tooltip').innerHTML = this.favorites_copy.inactive
  }

  setItineraryToActive () {
    this.in_itinerary = true
    this.itineraryBtnTarget.classList.add('active')
    this.itineraryBtnTarget.querySelector('.action-pill-tooltip').innerHTML = this.itinerary_copy.active
  }

  setItineraryToInactive () {
    this.in_itinerary = false
    this.itineraryBtnTarget.classList.remove('active')
    this.itineraryBtnTarget.querySelector('.action-pill-tooltip').innerHTML = this.itinerary_copy.inactive
  }

  setVisitedToActive () {
    this.in_visited = true
    this.visitedBtnTarget.classList.add('active')
    this.visitedBtnTarget.querySelector('.action-pill-tooltip').innerHTML = this.visited_copy.active
  }

  setVisitedToInactive () {
    this.in_visited = false
    this.visitedBtnTarget.classList.remove('active')
    this.visitedBtnTarget.querySelector('.action-pill-tooltip').innerHTML = this.visited_copy.inactive
  }  
}
