import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['trigger']

  TRIGGER_STATES = {
    loading: 'loading',
    success: 'success',
    error: 'error',
    init: 'init'
  }

  connect () {
    if (!('geolocation' in navigator)) {
      this.triggerTarget.classList.add('-hidden')
    }
  }

  reset () {
    this._setTriggerState('init')
  }

  geoLocate (e) {
    e.preventDefault()
    this._setTriggerState(this.TRIGGER_STATES.loading)
    const geocoder = new window.google.maps.Geocoder()
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const latLng = { lat: coords.latitude, lng: coords.longitude }
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            if (window.algMap != null) {
              const { formatted_address: addr } = results[0]
              const currentLocation = new window.google.maps.LatLng(coords.latitude, coords.longitude)
              window.algMap.setCenter(currentLocation)
              window.algMap.setZoom(10)
              this._setTriggerState(this.TRIGGER_STATES.success, addr)
            } else {
              this._setTriggerState(this.TRIGGER_STATES.error)
            }
          } else {
            this._setTriggerState(this.TRIGGER_STATES.error)
          }
        } else {
          this._setTriggerState(this.TRIGGER_STATES.error)
        }
      })
    }, () => {
      this._setTriggerState(this.TRIGGER_STATES.error)
    })
  }

  _addCoordInputs (lat, lng) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'coordinates'
    input.value = [lat, lng].join(',')
    this.formTarget.appendChild(input)
  }

  _setTriggerState (state, msg) {
    const { TRIGGER_STATES } = this
    switch (state) {
      case TRIGGER_STATES.loading: {
        this.triggerTarget.innerHTML = '<i class="fa fa-spinner fa-spin fa-fw animated"></i>Finding your location...'
        this.triggerTarget.classList.remove('-success', '-error')
        return
      }
      case TRIGGER_STATES.error: {
        this.triggerTarget.innerHTML = "<i class=\"fa fa-times animated\"></i>Couldn't find your location"
        this.triggerTarget.classList.add('-error')
        return
      }
      case TRIGGER_STATES.success: {
        this.triggerTarget.innerHTML = `<i class="fa fa-check animated"></i>Location Found: ${msg}`
        this.triggerTarget.classList.add('-success')
        return
      }
      case TRIGGER_STATES.init: {
        this.triggerTarget.innerHTML = '<i class="fa fa-location-arrow animated"></i>Use your current location'
        this.triggerTarget.classList.remove('-success', '-error')
      }
    }
  }
}
