import { Controller } from 'stimulus'
import IMask from 'imask'

export default class extends Controller {
  static targets = ['zipcode'];

  connect() {
    this.createZipcodeMask();
  }

  createZipcodeMask () {
    IMask(this.zipcodeTarget, {
      mask: '#0000',
      definitions: {
        '#': /[0-9]/
      }
    })
  }
}
