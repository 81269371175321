import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['map', 'result', 'locationInput', 'form']

  DEFAULT_COORDS = {
    lat: 37.546546,
    lng: -77.443605
  }

  iconPinURL = 'https://vaw-public-prod.s3.amazonaws.com/map_icons/icon-pin.svg'
  iconPinSelectedURL = 'https://vaw-public-prod.s3.amazonaws.com/map_icons/icon-pin-selected.svg'

  iconParams = {
    url: this.iconPinURL,
    size: new window.google.maps.Size(26, 35),
    labelOrigin: new window.google.maps.Point(13, 12)
  }

  map = {}
  markers = []
  infoWindow = null

  connect () {
    if (this.hasMapTarget) {
      this.infoWindow = new window.google.maps.InfoWindow()
      this.initMap()
      this.placeMarkers()
    }
  }

  initMap () {
    const latLng = new window.google.maps.LatLng(
      this.DEFAULT_COORDS.lat,
      this.DEFAULT_COORDS.lng
    )
    this.map = new window.google.maps.Map(this.mapTarget, {
      center: latLng,
      mapTypeControl: false,
      streetViewControl: false,
      zoom: 5,
      minZoom: 6,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_TOP
      }
    })
  }

  placeMarkers () {
    const latLngBounds = new window.google.maps.LatLngBounds()
    const resultType = this.mapTarget.dataset.resultType
    this.resultTargets.forEach((result, index) => {
      const lat = parseFloat(result.getAttribute('data-latitude'))
      const lng = parseFloat(result.getAttribute('data-longitude'))
      if (!lat || !lng || lat === 0 || lat === 0) return

      const latLng = new window.google.maps.LatLng(lat, lng)
      latLngBounds.extend(latLng)
      this.createMarker(resultType, result, latLng, index)

      const pin = result.querySelector('[data-result-pin]')
      pin.addEventListener('click', () => {
        const marker = this.markers[index]
        const resultInfo = this.resultInfo(resultType, result)
        this.setMarkerActive(marker)
        this.infoWindow.close()
        this.infoWindow.setContent(this.infoWindowContent(resultType, resultInfo))
        this.infoWindow.open(this.map, marker)
      })
    })
    this.map.fitBounds(latLngBounds)
  }

  searchFromMap (e) {
    e.preventDefault()
    const latlng = this.map.getCenter()
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        this.locationInputTarget.value = results[0].formatted_address
      } else {
        const coords = latlng.lat() + ',' + latlng.lng()
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'coordinates'
        input.value = [latlng.lat(), latlng.lng()].join(',')
        this.formTarget.appendChild(input)
        this.locationInput.value = coords
      }
      this.formTarget.submit()
    })
  }

  setMapFromPin (e) {
    e.preventDefault()
  }

  createMarker (resultType, result, latLng, index) {
    const resultInfo = this.resultInfo(resultType, result)
    const marker = new window.google.maps.Marker({
      position: latLng,
      map: this.map,
      icon: this.iconParams,
      label: {
        text: (index + 1).toString(),
        color: '#ffffff',
        fontFamily: 'FFMarkStdMedium',
        fontSize: '12px'
      }
    })
    window.google.maps.event.addListener(marker, 'click', () => {
      this.setMarkerActive(marker)
      this.infoWindow.close()
      this.infoWindow.setContent(this.infoWindowContent(resultType, resultInfo))
      this.infoWindow.open(this.map, marker)
    })
    this.markers.push(marker)
  }

  resultInfo (resultType, result) {
    if (resultType === 'static') {
      return {
        title: result.querySelector('[data-result-heading]').innerHTML,
        address: result.querySelector('address').innerHTML
      }
    }
    return {
      title: result.querySelector('.result-heading a').text,
      url: result.querySelector('.result-heading a').getAttribute('href'),
      address: result.querySelector('address').innerHTML,
      distance: this.getDistance(result)
    }
  }

  setMarkerActive (marker) {
    this.markers.forEach(m => {
      m.setZIndex(0)
      m.setIcon(this.iconParams)
    })
    marker.setIcon({ ...this.iconParams, ...{ url: this.iconPinSelectedURL } })
    marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1)
  }

  infoWindowContent (resultType, vars) {
    if (resultType === 'static') {
      return this.staticWindowContent(vars)
    } else {
      return this.linkedWindowContent(vars)
    }
  }

  staticWindowContent (vars) {
    let content = '<div class="map__infowindow">'
    content += '<p class="map__infowindow-heading">' + vars.title + '</p>'
    content += '<p class="map__infowindow-address">' + vars.address + '</p>'
    content += '</div>'
    return content
  }

  linkedWindowContent (vars) {
    let content = '<div class="map__infowindow">'
    if (vars.distance !== false) {
      content += '<p class="map__infowindow-distance">' + vars.distance + '</p>'
    }
    content += '<a class="map__infowindow-heading" href="' + vars.url + '">' + vars.title + '</a>' +
      '<p class="map__infowindow-address">' + vars.address + '</p>' +
      '</div>'
    return content
  }

  getDistance (result) {
    if (result.getAttribute('data-distance') === '') {
      return false
    } else {
      return Number(result.getAttribute('data-distance')).toFixed(1) + ' miles'
    }
  }
}
