import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'filterList',
    'featureFilterCheckbox',
    'wineFilterCheckbox',
    'eventFilterCheckbox',
    'form',
    'regionInput',
    'featuresInput',
    'winesInput',
    'eventsInput'
  ]

  resetRegion = (e) => {
    e.preventDefault()
    this.regionInputTarget.value = ''
    this.formTarget.submit()
  }

  resetFilters = () => {
    if (this.hasFeaturesInputTarget) {
      this.featureFilterCheckboxTargets.forEach(filter => { filter.checked = false })
      this.handleFeatureFilterClick()
    }
    if (this.hasWinesInputTarget) {
      this.wineFilterCheckboxTargets.forEach(filter => { filter.checked = false })
      this.handleWineFilterClick()
    }
    if (this.hasEventsInputTarget) {
      this.eventFilterCheckboxTargets.forEach(filter => { filter.checked = false })
      this.handleEventFilterChange()
    }
  }

  handleFeatureFilterClick = () => {
    this._updateFilterList(this.featureFilterCheckboxTargets, this.featuresInputTarget)
  }

  handleWineFilterClick = () => {
    this._updateFilterList(this.wineFilterCheckboxTargets, this.winesInputTarget)
  }

  handleEventFilterChange = () => {
    this._updateFilterList(this.eventFilterCheckboxTargets, this.eventsInputTarget)
  }

  _updateFilterList = (targets, input) => {
    const activeFilters = []
    targets.forEach((filter) => {
      if (filter.checked) {
        activeFilters.push(filter.value)
      }
    })
    input.value = activeFilters.join(',')
  }
}
