import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['list', 'icon']

  toggleList () {
    this.listTarget.classList.toggle('-open')
    this.iconTarget.classList.toggle('-open')
  }
}
