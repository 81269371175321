import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  dateConfig = {
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d'
  }

  connect () {
    window.flatpickr(this.inputTarget, this.dateConfig)
  }
}
