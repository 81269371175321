import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['map', 'filters']

  openMap (e) {
    e.preventDefault()
    this.fixBody()
    this.mapTarget.classList.add('-open')
    this.filtersTarget.classList.remove('-open')
  }

  openFilters (e) {
    e.preventDefault()
    this.fixBody()
    this.filtersTarget.classList.add('-open')
    this.mapTarget.classList.remove('-open')
  }

  close (e) {
    this.mapTarget.classList.remove('-open')
    this.filtersTarget.classList.remove('-open')
    this.unfixBody()
  }

  fixBody () {
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollY}px`
  }

  unfixBody () {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
}
