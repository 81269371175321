import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  DISPLAY_LENGTH = 4000
  HIDE_CLASS = 'closed'

  close () {
    this.containerTarget.parentNode.classList.add(this.HIDE_CLASS)
    // Call "setCookie()"  if the alert should not reappear after the user
    // closes the alert banner
  }

  setCookie () {
    document.cookie = 'alert_banner=clicked'
  }
}
