import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['virtualCheckbox', 'address']

  connect () {
    this.setAddressDisplay(this.virtualCheckboxTarget.checked)
  }

  toggleAddress = (e) => {
    this.setAddressDisplay(e.target.checked)
  }

  setAddressDisplay = (isVirtual) => {
    this.addressTarget.style.display = isVirtual ? 'none' : 'block'
  }
}
