import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['open', 'close', 'menu']

  connect () {
    this.menuTarget.classList.add('-transitions')
  }

  disconnect () {
    this.menuTarget.classList.remove('-transitions')
  }

  openMenu () {
    this.menuTarget.classList.add('-open')
  }

  closeMenu () {
    this.menuTarget.classList.remove('-open')
  }
}
