import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'streetAddress',
    'extendedAddress',
    'city',
    'state',
    'zipcode'
  ]

  setAddress () {
    this.streetAddressTarget.value = this.data.get('street-address')
    this.extendedAddressTarget.value = this.data.get('extended-address')
    this.cityTarget.value = this.data.get('city')
    this.stateTarget.value = this.data.get('state')
    this.zipcodeTarget.value = this.data.get('zipcode')
  }

  toggleAddress (e) {
    e.preventDefault()
    this.setAddress()
  }
}
