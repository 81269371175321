import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'inputMenu', 'hiddenInput']

  closeMenu () {
    this.inputMenuTarget.classList.remove('-active')
  }

  openMenu (e) {
    this.inputMenuTarget.classList.add('-active')
  }
}
