import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  dateConfig = {
    enableTime: true,
    altInput: true,
    altFormat: 'F j, Y h:iK',
    dateFormat: 'Y-m-d H:i',
    minuteIncrement: 15,
    onClose: this._onDateClose.bind(this),
    disableMobile: 'true'
  }

  connect () {
    this.inputTargets.forEach(el => {
      window.flatpickr(el, this.dateConfig)
    })
  }

  _getEndedAtInput = () => {
    return this.inputTargets.find(input => {
      return input.id === 'event_ended_at'
    })
  }

  _onDateClose (selectedDates, dateStr, instance) {
    if (instance.input.id === 'event_started_at' && this._getEndedAtInput().value === '') {
      const endsAt = this._getEndedAtInput()
      const newDate = new Date(dateStr)
      newDate.setHours(newDate.getHours() + 1)
      endsAt._flatpickr.setDate(newDate)
    }
  }
}
