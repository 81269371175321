import { Controller } from 'stimulus'
import { endOfWeek, subDays } from 'date-fns'

export default class extends Controller {
  static targets = ['startedAt', 'endedAt']

  dateConfig = {
    altInput: true,
    altFormat: 'm/d/Y',
    dateFormat: 'Y-m-d',
    disableMobile: 'true',
    minDate: new Date().setHours(0, 0, 0, 0)
  }

  startedAt = {}
  endedAt = {}

  connect () {
    this.startedAt = window.flatpickr(this.startedAtTarget, {
      ...this.dateConfig,
      onClose: this.setMinEndDate.bind(this)
    })
    this.endedAt = window.flatpickr(this.endedAtTarget, this.dateConfig)
  }

  setMinEndDate (selectedDates, dateStr, instance) {
    this.endedAt.set('minDate', dateStr)
  }

  selectDate (e) {
    e.preventDefault()
    const dateType = e.target.getAttribute('data-date')
    const dates = this._getDateRange(dateType)
    this.startedAtTarget._flatpickr.setDate(dates[0])
    this.endedAtTarget._flatpickr.setDate(dates[1])
  }

  _getDateRange (type) {
    switch (type) {
      case 'today':
        return [new Date(), new Date()]
      case 'tomorrow': {
        const date = new Date()
        const tomorrow = date.setDate(date.getDate() + 1)
        return [tomorrow, tomorrow]
      }
      case 'week':
        return [new Date(), this.getDayOfWeek(7)]
      case 'weekend':
        return this.getThisWeekendRange()
      default:
    }
  }

  getThisWeekendRange () {
    const endDate = endOfWeek(new Date(), { weekStartsOn: 1 })
    const startDate = subDays(endDate, 2)
    return [startDate, endDate]
  }

  getDayOfWeek (day) {
    const d = new Date()
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() + day)
  }
}
