import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['list', 'input']

  wineries = []
  currentFocus = -1
  term = ''

  connect () {
    fetch('/wineries/autocomplete.json')
      .then(response => { return response.json() })
      .then((wineries) => { this.wineries = wineries })
    document.addEventListener('click', this.onClickAwayFromInput.bind(this))
  }

  disconnect () {
    document.removeEventListener('click', this.onClickAwayFromInput.bind(this))
  }

  onInputChange (e) {
    this.term = e.target.value

    if (this.term.length > 2) {
      const results = this.wineries.filter(({ name }) => {
        return name.toLowerCase().includes(this.term.toLowerCase())
      })
      this._updateList(results, this.term)
    } else {
      this.listTarget.classList.add('-hidden')
    }
  }

  onClickAwayFromInput (e) {
    if (this.listTarget.classList.contains('-hidden')) {
      return
    }
    if (e.target !== this.listTarget && e.target !== this.inputTarget) {
      this._unsetAutocomplete()
    }
  }

  onKeyboardPress (e) {
    const listItems = this.listTarget.children
    if (listItems.length > 0) { // Down Button
      if (e.keyCode === 40) {
        if (this.currentFocus < (listItems.length - 1)) {
          this.currentFocus++

          if (this.listTarget.classList.value.indexOf('-hidden') !== -1) {
            this.listTarget.classList.remove('-hidden')
          }

          this.inputTarget.value = listItems[this.currentFocus].querySelector('p').textContent

          if (this.currentFocus > 5) {
            this.listTarget.scrollTop = this.listTarget.scrollTop + 49
          }

          if (listItems[this.currentFocus].previousSibling !== null) {
            listItems[this.currentFocus].previousSibling.classList.remove('-active')
          }

          listItems[this.currentFocus].classList.add('-active')
        } else {
          this.inputTarget.value = this.term

          this.currentFocus = -1
          listItems[listItems.length - 1].classList.remove('-active')
        }
      } else if (e.keyCode === 38) { // Up Button
        if (this.currentFocus > 0) {
          this.currentFocus--

          this.inputTarget.value = listItems[this.currentFocus].querySelector('p').textContent

          this.listTarget.scrollTop = this.listTarget.scrollTop - 49

          listItems[this.currentFocus].classList.add('-active')

          if (listItems[this.currentFocus].nextSibling !== null) {
            listItems[this.currentFocus].nextSibling.classList.remove('-active')
          }
        } else {
          this.inputTarget.value = this.term

          this.currentFocus = -1
          listItems[0].classList.remove('-active')
        }
      } else if (e.keyCode === 13) { // Enter Button
        /* If the ENTER key is pressed, prevent the form from being submitted, */
        e.preventDefault()
        const winery = this.listTarget.children[this.currentFocus].getAttribute('data-winery')
        this.term = winery
        this.inputTarget.value = winery
        this.listTarget.classList.add('-hidden')
      } else if (e.keyCode === 27) {
        this._unsetAutocomplete()
      }
    }
  }

  onWineryClick (e) {
    e.preventDefault()
    const winery = e.currentTarget.getAttribute('data-winery')
    this.term = winery
    this.inputTarget.value = winery
    this.listTarget.classList.add('-hidden')
  }

  _unsetAutocomplete () {
    this.listTarget.scrollTop = 0
    this.inputTarget.value = this.term
    this.currentFocus = -1
    this.listTarget.classList.add('-hidden')

    if (this.listTarget.querySelectorAll('.-active').length > 0) {
      this.listTarget.querySelector('.-active').classList.remove('-active')
    }
  }

  _updateList (results, term) {
    const markup = results.map(({ name, cityState }) => {
      const highlightedName = this._highlightTermInName(name, term)
      return `<a data-action="click->event-autocomplete#onWineryClick" data-winery="${name}" class='search-box__autocomplete__item'>
               <p class="search-box__autocomplete-heading">${highlightedName}</p>
               <span class="search-box__autocomplete-subheading">${cityState}</span>
             </a>`
    })

    this.listTarget.innerHTML = markup.join('')
    this.listTarget.classList.remove('-hidden')
  }

  _highlightTermInName (name, term) {
    const start = name.toLowerCase().indexOf(term.toLowerCase())
    const highlight = '<b>' + name.slice(start, start + term.length) + '</b>'
    return name.substr(0, start) + highlight + name.substr(start + term.length)
  }
}
