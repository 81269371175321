import c0 from './accordion_controller';
import c1 from './actionpill_controller';
import c2 from './alert_banner_controller';
import c3 from './autocomplete_controller';
import c4 from './ckeditor_controller';
import c5 from './date_range_controller';
import c6 from './dates_controller';
import c7 from './detail_map_controller';
import c8 from './event_address_controller';
import c9 from './event_autocomplete_controller';
import c10 from './event_dates_controller';
import c11 from './event_owner_controller';
import c12 from './exchange_board_controller';
import c13 from './filter_toggle_controller';
import c14 from './flash_controller';
import c15 from './form_attachments_controller';
import c16 from './govcup_wine_controller';
import c17 from './hours_controller';
import c18 from './hparty_controller';
import c19 from './image_uploads_controller';
import c20 from './location_controller';
import c21 from './masks_controller';
import c22 from './mobile_menu_controller';
import c23 from './mobile_results_controller';
import c24 from './region_list_controller';
import c25 from './resource_date_controller';
import c26 from './resource_filter_controller';
import c27 from './result_map_controller';
import c28 from './results_form_controller';
import c29 from './retailers_controller';
import c30 from './shipping_states_controller';
import c31 from './side_menu_controller';
import c32 from './tabform_controller';
import c33 from './virtual_event_controller';
export const definitions = [
	{identifier: 'accordion', controllerConstructor: c0},
	{identifier: 'actionpill', controllerConstructor: c1},
	{identifier: 'alert-banner', controllerConstructor: c2},
	{identifier: 'autocomplete', controllerConstructor: c3},
	{identifier: 'ckeditor', controllerConstructor: c4},
	{identifier: 'date-range', controllerConstructor: c5},
	{identifier: 'dates', controllerConstructor: c6},
	{identifier: 'detail-map', controllerConstructor: c7},
	{identifier: 'event-address', controllerConstructor: c8},
	{identifier: 'event-autocomplete', controllerConstructor: c9},
	{identifier: 'event-dates', controllerConstructor: c10},
	{identifier: 'event-owner', controllerConstructor: c11},
	{identifier: 'exchange-board', controllerConstructor: c12},
	{identifier: 'filter-toggle', controllerConstructor: c13},
	{identifier: 'flash', controllerConstructor: c14},
	{identifier: 'form-attachments', controllerConstructor: c15},
	{identifier: 'govcup-wine', controllerConstructor: c16},
	{identifier: 'hours', controllerConstructor: c17},
	{identifier: 'hparty', controllerConstructor: c18},
	{identifier: 'image-uploads', controllerConstructor: c19},
	{identifier: 'location', controllerConstructor: c20},
	{identifier: 'masks', controllerConstructor: c21},
	{identifier: 'mobile-menu', controllerConstructor: c22},
	{identifier: 'mobile-results', controllerConstructor: c23},
	{identifier: 'region-list', controllerConstructor: c24},
	{identifier: 'resource-date', controllerConstructor: c25},
	{identifier: 'resource-filter', controllerConstructor: c26},
	{identifier: 'result-map', controllerConstructor: c27},
	{identifier: 'results-form', controllerConstructor: c28},
	{identifier: 'retailers', controllerConstructor: c29},
	{identifier: 'shipping-states', controllerConstructor: c30},
	{identifier: 'side-menu', controllerConstructor: c31},
	{identifier: 'tabform', controllerConstructor: c32},
	{identifier: 'virtual-event', controllerConstructor: c33},
];
