import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'list',
    'eventableId',
    'eventableType',
    'ownerTextField',
    'input',
    'streetAddress',
    'extendedAddress',
    'city',
    'state',
    'zipcode',
    'manualCheckbox',
    'ownerTextInput'
  ]

  eventables = []
  addresses = []

  connect () {
    fetch('/admin/events/eventables.json')
      .then(response => {
        return response.json()
      })
      .then(({ data }) => {
        this.eventables = data
        this._prefillInput()
        this._initManualOwner()
      })
  }

  _initManualOwner () {
    if (this.ownerTextInputTarget) {
      this.ownerTextFieldTarget.classList.remove('-hidden')
      this.manualCheckboxTarget.checked = true
    }
  }

  _parseApiAddressData (data) {
    return [...data.wineries.included, ...data.businesses.included]
  }

  _prefillInput () {
    const existingId = this.eventableIdTarget.value
    const existingType = this.eventableTypeTarget.value
    if (existingId.length > 0 && existingType.length > 0) {
      const result = this.eventables.find(eventable => {
        return (
          eventable.id === parseInt(existingId) &&
          eventable.eventable_type.toLowerCase() === existingType.toLowerCase()
        )
      })
      if (result) {
        this.inputTarget.value = result.name
      }
    }
  }

  onSelectManualOwner (e) {
    if (e.target.checked) {
      this.ownerTextFieldTarget.classList.remove('-hidden')
    } else {
      this.ownerTextFieldTarget.classList.add('-hidden')
      this.ownerTextInputTarget.value = ''
    }
  }

  onInputChange (e) {
    const term = e.target.value
    if (term.length > 2) {
      const results = this.eventables.filter(eventable => {
        return eventable.name.toLowerCase().includes(term.toLowerCase())
      })
      this._updateList(results, term)
    } else {
      this.listTarget.classList.add('-hidden')
    }
  }

  _updateList (results, term) {
    const markup = results.map(eventable => {
      const name = this._highlightTermInName(eventable.name, term)
      return `<a href='#' data-action="event-owner#setEventable" data-name="${eventable.name}" data-id="${eventable.id}" data-type="${eventable.eventable_type}" class='search-box__autocomplete__item'>
               <p class="search-box__autocomplete-heading">
               ${name}
               </p>
               <span class="search-box__autocomplete-label -${eventable.eventable_type.toLowerCase()}">${eventable.eventable_type}</span>
             </a>`
    })

    this.listTarget.innerHTML = markup.join('')
    this.listTarget.classList.remove('-hidden')
  }

  setEventable (e) {
    e.preventDefault()
    const selection = e.currentTarget
    const id = selection.getAttribute('data-id')
    const type = selection.getAttribute('data-type')
    this.eventableIdTarget.value = selection.getAttribute('data-id')
    this.eventableTypeTarget.value = this._capitalize(
      selection.getAttribute('data-type')
    )
    this.inputTarget.value = selection.getAttribute('data-name')
    this.listTarget.classList.add('-hidden')
    this._setAddress(id, type)
  }

  _setAddress (id, type) {
    const eventable = this.eventables.find(eventable => {
      return (
        eventable.id === parseInt(id) &&
        eventable.eventable_type.toLowerCase() === type.toLowerCase()
      )
    })
    if (eventable.street_address) {
      this.streetAddressTarget.value = eventable.street_address
    }
    if (eventable.extended_address) {
      this.extendedAddressTarget.value = eventable.extended_address
    }
    if (eventable.city) {
      this.cityTarget.value = eventable.city
    }
    if (eventable.state) {
      this.stateTarget.value = eventable.state
    }
    if (eventable.zipcode) {
      this.zipcodeTarget.value = eventable.zipcode
    }
  }

  _highlightTermInName (name, term) {
    const start = name.toLowerCase().indexOf(term.toLowerCase())
    const highlight = '<b>' + name.slice(start, start + term.length) + '</b>'
    return name.substr(0, start) + highlight + name.substr(start + term.length)
  }

  _capitalize (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}
