import { Application } from 'stimulus'
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import { definitions } from 'stimulus:./controllers'

Rails.start()
ActiveStorage.start()

const app = Application.start()
app.load(definitions)
