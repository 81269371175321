import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['reply', 'form']

  openForm (e) {
    e.preventDefault()
    e.target.parentNode.querySelector('form').classList.toggle('-open')
  }
}
