import { DirectUpload } from '@rails/activestorage'

export default class UploadAdapter {
  constructor (loader, editor) {
    this.loader = loader
    this.editor = editor
    this.blobUrlTemplate = this.editor.config.get(
      'customUpload.blobUrlTemplate'
    )
    this.directUploadUrl = this.editor.config.get(
      'customUpload.directUploadUrl'
    )
  }

  upload () {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
          const directUpload = new DirectUpload(
            file,
            this.directUploadUrl,
            this
          )
          directUpload.create(this.directUploadDidComplete.bind(this))
        })
    )
  }

  directUploadWillStoreFileWithXHR (xhr) {
    this.xhr = xhr

    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        this.loader.uploadTotal = event.total
        this.loader.uploaded = event.loaded
      }
    })
  }

  abort () {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  directUploadDidComplete (error, attributes) {
    if (error) {
      this.reject(
        'Something went wrong with uploading the image. Please try again!'
      )
    }

    const url = this.createBlobUrl(attributes.signed_id, attributes.filename)

    this.resolve({ default: url })
  }

  createBlobUrl (signedId, filename) {
    return this.blobUrlTemplate
      .replace(':signed_id', signedId)
      .replace(':filename', encodeURIComponent(filename))
  }
}
