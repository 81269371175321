import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'fieldGroup']

  connect () {
    this.filterFields('billboard')
  }

  filterFields (tabType) {
    this.fieldGroupTargets.forEach(target => {
      target.style = 'display:block;'
      if (target.getAttribute('data-tabvalue') !== tabType) {
        target.style = 'display:none;'
      }
    })
    this.tabTargets.forEach(target => { target.classList.remove('-active') })
    this.tabTargets.find(target => { return target.getAttribute('data-tabvalue') === tabType }).classList.add('-active')
  }

  toggleTab (e) {
    e.preventDefault()
    const tabType = e.target.getAttribute('data-tabvalue')

    this.filterFields(tabType)
  }
}
