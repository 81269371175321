import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'preview', 'undo', 'fileInput']

  connect () {
    // don't flag attachment for purge since the user uploaded a new file
    this.fileInputTarget.addEventListener('change', () => { this.inputTarget.value = 0 })
  }

  remove (e) {
    e.preventDefault()
    this.inputTarget.value = 1
    this.previewTarget.classList.add('-hidden')
    this.undoTarget.classList.remove('-hidden')
  }

  undo (e) {
    e.preventDefault()
    this.inputTarget.value = 0
    this.previewTarget.classList.remove('-hidden')
    this.undoTarget.classList.add('-hidden')
  }
}
