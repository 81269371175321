import { Controller } from 'stimulus'
import UploadAdapter from '../helpers/upload_adapter'
const ClassicEditor = require('../vendor/ckeditor.cjs')

function ActiveStorageUploadAdapter (editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new UploadAdapter(loader, editor)
  }
}

export default class CKEditorController extends Controller {
  connect () {
    ClassicEditor.create(this.element, {
      extraPlugins: [ActiveStorageUploadAdapter],
      customUpload: {
        directUploadUrl: this.element.dataset.directUploadUrl,
        blobUrlTemplate: this.element.dataset.blobUrlTemplate
      },
      mediaEmbed: { previewsInData: true },
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'indent',
          'outdent',
          '|',
          'imageUpload',
          'blockQuote',
          'mediaEmbed',
          'htmlEmbed',
          'undo',
          'redo'
        ]
      }
    }).catch((error) => {
      console.error(error)
    })
  }
}
