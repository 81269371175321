import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'wineryNameHint',
    'winerySelectField',
    'winerySelect',
    'existingCheckbox'
  ]

  connect () {
    this.onSelectExistingWinery(new Event('change'))
  }

  onSelectExistingWinery (e) {
    if (this.existingCheckboxTarget.checked) {
      this.winerySelectFieldTarget.classList.remove('-hidden')
      this.wineryNameHintTarget.style.display = 'block'
    } else {
      this.winerySelectFieldTarget.classList.add('-hidden')
      this.winerySelectTarget.selectedIndex = -1
      this.wineryNameHintTarget.style.display = 'none'
    }
  }
}
