import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['section']

  toggleSection = ({ currentTarget }) => {
    const isOpen = currentTarget.parentNode.classList.contains('-active')

    this.sectionTargets.forEach(el => el.classList.remove('-active'))
    if (!isOpen) currentTarget.parentNode.classList.add('-active')
  }
}
