import { Controller } from 'stimulus'
import closeIcon from '../assets/images/ui/closeIcon.js'

export default class extends Controller {
  static targets = [
    'input',
    'shippingState',
    'menu',
    'stateList',
    'selectedList'
  ]

  shippingStates = {}

  connect () {
    this.shippingStates = this._setShippingStates()
    this.initializeStates()
  }

  initializeStates () {
    const selected = this.stateListTarget.querySelectorAll("input[type='checkbox']:checked")
    selected.forEach(el => {
      const parent = el.parentNode
      const state = parent.querySelector('label').innerText
      this.selectedListTarget.innerHTML = this.selectedListTarget.innerHTML +
        this._shippingStateTemplate(state)
    })
  }

  _shippingStateTemplate (state) {
    return `<span class="form__autocomplete__selected-state" data-action="click->shipping-states#removeState">
              ${closeIcon}
              <span>${state}</span>
            </span>`
  }

  _setShippingStates () {
    return this.shippingStateTargets.reduce((map, el) => {
      const name = el.querySelector('label').innerText
      const id = el.querySelector('input').value
      map[name] = id
      return map
    }, {})
  }

  _autoCompleteStates = (input) => {
    return Object.keys(this.shippingStates).filter(state => {
      return state.toLowerCase().includes(input.toLowerCase())
    })
  }

  _setAutocompleteMenu = (states) => {
    const markup = states.reduce((str, state) => {
      str += `<div data-action="click->shipping-states#addState" class="form__autocomplete__item">${state}</div>`
      return str
    }, '')
    this.menuTarget.innerHTML = markup
  }

  addState (e) {
    const state = e.target.innerText
    const id = this.shippingStates[state]
    this.stateListTarget.querySelector(`input[value="${id}"]`).checked = true
    this.selectedListTarget.innerHTML = this.selectedListTarget.innerHTML +
      this._shippingStateTemplate(state)
    this._resetInput()
  }

  removeState (e) {
    e.preventDefault()
    const { currentTarget } = e
    const state = currentTarget.querySelector('span').innerText
    const id = this.shippingStates[state]
    this.stateListTarget.querySelector(`input[value="${id}"]`).checked = false
    currentTarget.parentNode.removeChild(currentTarget)
  }

  _resetInput () {
    this.menuTarget.classList.remove('-open')
    this.inputTarget.value = ''
  }

  onInputChange (e) {
    if (this.inputTarget.value.length >= 3) {
      const states = this._autoCompleteStates(this.inputTarget.value)
      this._setAutocompleteMenu(states)
      this.menuTarget.classList.add('-open')
    } else {
      this.menuTarget.classList.remove('-open')
    }
  }
}
