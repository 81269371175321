import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper']

  DISPLAY_LENGTH = 4000
  HIDE_CLASS = 'bounceOutDown'

  connect () {
    setTimeout(() => {
      this.closeNotice()
    }, this.DISPLAY_LENGTH)
  }

  closeNotice () {
    this.wrapperTarget.classList.add(this.HIDE_CLASS)
  }
}
