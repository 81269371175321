import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['list', 'toggle']

  toggleList (e) {
    this.listTarget.classList.toggle('-open')
    this.toggleTarget.classList.toggle('-open')
  }
}
