import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['categoriesInput', 'categoryCheckbox', 'filterToggle', 'filterList', 'form']

  toggleFilterList (e) {
    this.filterListTarget.classList.toggle('-open')
    this.filterToggleTarget.classList.toggle('-open')
  }

  handleCategoryChange () {
    const activeFilters = []
    this.categoryCheckboxTargets.forEach((filter) => {
      if (filter.checked) {
        activeFilters.push(filter.value)
      }
      filter.disabled = true
    })
    this.categoriesInputTarget.value = activeFilters.join(',')
    this.categoriesInputTarget.form.submit()
  }
}
