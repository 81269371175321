import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['map']

  DEFAULT_COORDS = {
    lat: 37.546546,
    lng: -77.443605
  }

  map = {}
  marker = {}
  infoWindow = null

  connect () {
    this.initMap()
    this.placeMarker()
  }

  initMap () {
    const lat = parseFloat(this.mapTarget.getAttribute('data-lat'))
    const lng = parseFloat(this.mapTarget.getAttribute('data-lng'))
    const latLng = new window.google.maps.LatLng(lat, lng)
    this.map = new window.google.maps.Map(this.mapTarget, {
      center: latLng,
      mapTypeControl: false,
      streetViewControl: false,
      zoom: 9,
      zoomControl: false
    })
  }

  placeMarker () {
    const lat = parseFloat(this.mapTarget.getAttribute('data-lat'))
    const lng = parseFloat(this.mapTarget.getAttribute('data-lng'))
    if (lat !== 0 && lat !== 0) {
      const latLng = new window.google.maps.LatLng(lat, lng)
      this.createMarker(latLng)
    }
  }

  createMarker (latLng) {
    const marker = new window.google.maps.Marker({
      position: latLng,
      animation: window.google.maps.Animation.DROP,
      map: this.map,
      icon: {
        url: 'https://vaw-public-prod.s3.amazonaws.com/icon-pin.svg',
        size: new window.google.maps.Size(26, 35),
        anchor: new window.google.maps.Point(13, 37)
      }
    })
    this.marker = marker
  }
}
