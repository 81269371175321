import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countdown']

  minutes = 60
  hours = this.minutes * 60
  days = this.hours * 24
  end_date = new Date('October 19, 2019 00:00:00')
  interval = null

  connect () {
    this.startCountdown()
  }

  disconnect () {
    clearInterval(this.interval)
  }

  startCountdown () {
    this.updateCountdown()
    this.interval = setInterval(this.updateCountdown, 1000)
  }

  updateCountdown = () => {
    const now = new Date()
    const diff = this.end_date - now
    const date = this.getIntervalValues(diff).join(':')
    this.countdownTarget.innerHTML = date
  }

  getIntervalValues = date => {
    let delta = Math.floor(date / 1000)

    return [this.days, this.hours, this.minutes, 1].map(int => {
      const count = Math.floor(delta / int)
      delta -= count * int
      return this.addLeadingZero(count)
    })
  }

  addLeadingZero = val => {
    if (val >= 10) return val
    return `0${val}`
  }
}
