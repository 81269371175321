import flatpickr from 'flatpickr'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['date', 'time']

  dateConfig = {
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d'
  }

  timeConfig = {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'h:i K'
  }

  connect () {
    this._initDateRanges()
    this._initTimeRanges()
  }

  onCloseClick = e => {
    const toState = e.target.checked ? 'close' : 'open'
    this._input_range_for_checkbox(e.target).forEach(el => {
      this._toggleClosedState(el, toState)
    })
  }

  _initDateRanges = () => {
    if (this.dateTargets) {
      this.dateTargets.forEach(el => {
        flatpickr(el, this.dateConfig)
      })
    }
  }

  _initTimeRanges = () => {
    if (this.timeTargets) {
      this.timeTargets.forEach(el => {
        flatpickr(el, this.timeConfig)
        setTimeout(() => {
          if (el.id.includes('open') && el.value === '') {
            this._input_checkbox_for_hours_input(el).checked = true
            this._input_range_for_checkbox(el).forEach(input => {
              this._toggleClosedState(input)
            })
          }
        }, 0)
      })
    }
  }

  _toggleClosedState = (el, state = 'close') => {
    if (el._flatpickr) {
      el._flatpickr.clear()
    }
    if (state === 'close') {
      el.placeholder = 'Closed'
      el.value = ''
    }
    el.readOnly = state === 'close'
  }

  _input_range_for_checkbox = el => {
    return el
      .closest('.form__hours-fields__field')
      .querySelectorAll('input[type="text"]')
  }

  _input_checkbox_for_hours_input = el => {
    return el
      .closest('.form__hours-fields__field')
      .querySelector('input[type="checkbox"]')
  }
}
