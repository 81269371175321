import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'fields']

  onTabClick = e => {
    e.preventDefault()
    if (!e.target.classList.contains('-active')) {
      this.tabTargets.forEach(el => { el.classList.remove('-active') })
      e.target.classList.add('-active')
      const season = e.target.getAttribute('data-season')
      this.toggleFields(season)
    }
  }

  toggleFields = season => {
    this.fieldsTargets.forEach(target => {
      if (target.getAttribute('data-season') === season) {
        target.classList.add('-active')
      } else {
        target.classList.remove('-active')
      }
    })
  }
}
